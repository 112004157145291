<template>
    <div>
        <b-sidebar id="menu-principal" backdrop shadow bg-variant="light" text-variant="light" no-header>
            <b-list-group>

                <b-list-group-item variant="light" href="#/" v-b-toggle.menu-principal :active="isAactive('/')"><b-icon
                        icon="house"></b-icon> Home</b-list-group-item>
                <b-list-group-item variant="light" href="#/cadastro-movimentacao" v-b-toggle.menu-principal
                    :active="isAactive('/cadastro-movimentacao')"><b-icon icon="plus"></b-icon> Cadastro de
                    Movimentacao</b-list-group-item>
                <b-list-group-item variant="light" href="#/gestao-contas" v-b-toggle.menu-principal
                    :active="isAactive('/gestao-contas')"><b-icon icon="plus"></b-icon> Gestão de
                    Contas</b-list-group-item>
            </b-list-group>
        </b-sidebar>


    </div>
</template>

<script>
export default {
    data() {
        return {
            active: false,
            clock: null,
            time: 0,
            callBack: undefined
        }
    },
    beforeDestroy() {
        clearInterval(this.clock)

    },
    mounted() {
    },
    methods: {
        isAactive(path) {
            return path == this.$root._route.path;
        }
    }
}
</script>