<template>
    <div>
       <b-modal v-model="$store.state.app.loadingActive" ref="modal-loading" title="Loading..."
            size="sm"
            centered
            headerClass= 'p-2 border-bottom-0'
            footerClass= 'p-2 border-top-0'
            no-close-on-backdrop
            no-close-on-esc
            cancel-disabled
            ok-only
            hide-footer
            hide-header
       >
         <b-spinner variant="success"  ></b-spinner>  <span class="aguarde"> Aguarde ...</span>
         <b-alert :show="$store.state.app.loadingMsg != ''" >{{$store.state.app.loadingMsg}}</b-alert>
       </b-modal>
    </div>
</template>
<style scoped>
.aguarde {
    font-size: 20px;
}
</style>
<script>
export default {
    data() {
        return {
            active:false,
            clock:null,
            time:0,
            callBack:undefined
        }
    },
    beforeDestroy() {
        clearInterval(this.clock)        
    },
    mounted() {
        this.init()
    },
    methods: {
        init() {
            
        }
    },
}
</script>