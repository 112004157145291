<template>
    <div>
         <b-breadcrumb   :items="getLista()"></b-breadcrumb>
    </div>
</template>

<script>


export default  {
 data() {
    return {
    
    }
 },
 methods: {
    getLista() {
      var lista = [];

     

      lista.push(
        {
          text: this.$route.name,
          active: true
        }
      )
      
        return lista;
    }
 },
}

</script>
