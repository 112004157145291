<template>
  <div class="">
    <side-menu/>
    <nave-bar/>
    <bread-crumb/>
    <router-view></router-view>
    <Loading/>
    </div>
</template>
<script>
import Loading from '@/components/Loading'
import SideMenu from '@/components/SideMenu.vue'
import NaveBar from '@/components/NaveBar.vue'
import BreadCrumb from '@/components/BreadCrumb.vue'
export default {
   components: {
     Loading,
     SideMenu,
     NaveBar,
     BreadCrumb

   },
  name: 'app'
}
</script>
